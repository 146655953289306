<template>
  <div class="domain_content">
    <h1 class="section-1-rule">
      GB WhatsApp Download APK (Updated) September 2024｜Login FIXED Version
    </h1>

    <p>
      Looking for a WhatsApp alternative with extra perks? GB WhatsApp is your
      go-to mod with more privacy, control, and flair for your chats. Enjoy
      GBWhatsApp novelty features and customization that attracts millions
      globally. Experience the best in messaging with GB WhatsApp, and take your
      chat game to the next level!
    </p>

    <myimg name="i1" alt="gbwhatsapp apk" />

    <DownloadBtn
      class="top-download has-exp"
      :exp-data="`show_${pageName}home_download`"
      :name="pageName"
      txt="Download GBWhatsApp"
      :from="from"
      :filename="filename"
      :isOffical="isOffical"
    />

    <category :arr="cate" />
    <h3 id="d1">
      How to fix "You need the official WhatsApp to log in" issue?
    </h3>
    <p>
      Some people having problems with you need the official WhatsApp to use
      this account recently. This question is currently of concern to many
      people. They want to know how to solve the GB WhatsApp ban problem, don't
      worry, I have good news for you.
    </p>
    <h4>Why Does WhatsApp Ban Your Account?</h4>
    <p>
      WhatsApp always update their terms and conditions to improve security and
      privacy. Many WhatsApp mods exist, like GB WhatsApp Pro v17.76, FM
      WhatsApp, WhatsApp plus and many more.
    </p>
    <p>
      These mods give you many extra features. These are third-party apps, and
      WhatsApp thinks they can steal your data, so they temporarily ban some
      accounts. To avoid this ban issue, download the GBWhatsApp mod version
      from a trusted third-party website.
    </p>
    <p><strong>Here are some other reasons that cause bans:</strong></p>
    <ul>
      <li>
        Sending messages in bulk to any contact violates WhatsApp's terms and
        conditions.
      </li>
      <li>
        WhatsApp does not allow you to share inappropriate content and files. If
        WhatsApp finds such content, your number will be banned.
      </li>
      <li>Share adult messages, images, and videos.</li>
      <li>Give hate and defamatory statements to any person.</li>
      <li>Send emails that may contain viruses or malware.</li>
      <li>Send someone bulk messages.</li>
      <li>Delete everyone continuously.</li>
      <li>Change group admins randomly.</li>
      <li>Broadcast spamming messages and media.</li>
      <li>Send one message to any contact multiple times.</li>
    </ul>

    <h4>How to use GB WhatsApp without being banned</h4>
    <ul class="nodot">
      <li>
        1. Download the latest version of GB WhatsApp APK with anti-ban enhanced
        from our site, you are guaranteed to get the most updated and safe
        version.
        <DownloadBtn
          class="top-download has-exp"
          :exp-data="`show_${pageName}home_download`"
          :name="pageName"
          txt="Safe Download"
          :from="from"
          :filename="filename"
          :isOffical="isOffical"
        />
      </li>
      <li>
        2. Keep the GBWhatsApp mod version updated always from this website.
      </li>
      <li>3. Don't send any spam messages.</li>
      <li>4. Don't share any inappropriate media and messages.</li>
      <li>5. Follow the terms and conditions of WhatsApp.</li>
    </ul>

    <h3 id="d2">GBWhatsApp vs WhatsApp – What's the Difference?</h3>
    <p>
      For those of you wondering what the difference is between GB WhatsApp and
      WhatsApp and whether you should try GB WhatsApp APK yourself, please read
      the following. These include features such as attractive themes, the
      ability to hide internet status, enabling airplane mode, and more. Below
      is a comparison of some of the main features of GBWhatsApp versus
      WhatsApp, and you will be attracted.
    </p>

    <table>
      <thead>
        <tr>
          <th>Features</th>
          <th>GBWhatsApp</th>
          <th>WhatsApp</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Auto-reply Messages</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Unread Messages</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Anti-deleted Messages</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Hide Blue Ticks</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Themes</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Save Status</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>DND Mode</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Change Chat Bubble</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>More Fonts</td>
          <td>✔️</td>
          <td>❌</td>
        </tr>
        <tr>
          <td>Share Images</td>
          <td>Max 100</td>
          <td>Max 90</td>
        </tr>
      </tbody>
    </table>

    <h3 id="d3">GBWhatsApp APK features</h3>
    <p>
      GBWhatsApp(WhatsApp GB) offers many custom features and privacy options. Here we select some typical features for you:
    </p>

    <div class="outli">
      <myli :arr="liarr" gbc/>
    </div>

    <h3 id="d4">GB WhatsApp Installation and Update</h3>
    <h4>WhatsApp GB Installation Guide</h4>
    <p>
      Now you want to download and install the GBWhatsApp MOD version right? but it cannot be found on Google Play, so you need to find it on a third-party website. If you are not familiar with the process of downloading the GB WhatsApp APK on Android device, you can refer to this installation guide.
    </p>

    <p>
      To install an APK file on Android, your Android phone may pop up a window to notice you it's a unknown source. So, you should go to the Settings of your phone. Then, give the permission to install GB WhatsApp. 
    </p>
    <myimg name='dd4' alt='install gb whatsapp' />
    <p>To learn more details about the features of GB WhatsApp, you can go to our <a style="color: #2168ee;" target="_blank" href="/blogs">blogs</a> page. </p>
    
    <h4>GBWhatsApp Update History:</h4>
   <p>GB WhatsApp has different developers team. Here we list some updated history from Alexmods team:</p>
   <ul>
    <li>Version: 18.00
        <ul class="nodot">
            <li>Improved Anti-Ban Protection</li>
            <li>Fix Bugs</li>
            <li>Add New Features</li>
        </ul>
    </li>
    <li>Version: 17.85
        <ul class="nodot">
            <li>Improved Anti-Ban Protection</li>
        </ul>
    </li>
    <li>Version: 17.80</li>
    <li>Version: 17.76</li>
    <li>Version: 17.70
        <ul class="nodot">
            <li>Improved Anti-Ban Protection</li>
        </ul>
    </li>
    <li>Version: 17.60
        <ul class="nodot">
            <li>Added 25+ New Feature</li>
        </ul>
    </li>
    <li>Version: 17.57
        <ul class="nodot">
            <li>Fixed Version Expired</li>
        </ul>
    </li>
    <li>Version: 17.55
        <ul class="nodot">
            <li>Fixed: Version Expired</li>
            <li>Improved: Anti-Ban Protection</li>
        </ul>
    </li>
</ul>

   <h3 id="d5">How to Backup GB WhatsApp Chats to Google Drive </h3>
   <p>As we all know, the official WhatsApp connect backup feature to Google Account. But GBWhatsApp as a modified version doesn't support it. So, how to transfer GBWhatsApp chat to Google Drive? Follow the steps below:</p>
   <p><strong>Step 1: Update GBWhatsApp backup</strong></p>
<ul>
    <li>Launch your GB WhatsApp and go to <strong>GBSettings</strong> &lt; <strong>Chat</strong>.</li>
    <li>Next, select "chat backup". Then, a backup of GB WhatsApp will be updated in your Android phone's local drive.</li>
    <li>Download and install WhatsApp on your phone. No need to delete WhatsApp GB.</li>
</ul>

<p><strong>Step 2: Replace WhatsApp backup with GBWhatsApp backup</strong></p>
<ul>
    <li>Android has a <strong>File Manager</strong> app on your phone, you should enter it and find the GB WhatsApp folder. Then, find <strong>Database</strong>.</li>
    <li>Under Database, copy this file: <strong>msgstore.db.crypt12</strong>. This is the backup data of GB WhatsApp.</li>
    <li>Then, go to the WhatsApp directory, and paste <strong>msgstore.db.crypt12</strong> in the same location like GB WhatsApp. This action will replace the WhatsApp backup with the GBWhatsApp backup.</li>
    <li>Now, launch WhatsApp and log in with the same account as in GB WhatsApp. After logging in successfully, we'll do the last step.</li>
</ul>

<p><strong>Step 3: Backup WhatsApp chat to Google account</strong></p>
<ul>
    <li>Do WhatsApp chat backup to your Google Account's storage.</li>
    <li>Up to this point, your GB WhatsApp has been uploaded to Google Drive.</li>
</ul>

  <h2>Conclusion</h2>
  <p>
    WhatsApp GB is loved and used by tens of thousands of users because of its rich functions. Most people use it without security problems, but no matter what, it is developed by third parties. It is recommended that if you want to download and use it, please be sure to abide by the official WhatsApp terms and conditions to avoid having your account blocked. 
  </p>
   <!-- 评论 -->
    <div id="comment-box">
      <div class="title">Leave a Comment</div>
      <input type="text" class="nameClass" v-model="comment.name" placeholder="your name" />
      <input type="email" class="emailClass" v-model="comment.email" placeholder="Email" />
      <textarea class="contentClass" v-model="comment.content"></textarea>
      <button class="submitClass" @click="submitClick">Submit</button>
      <div class="commentList-box">
        <div class="commentClass" v-for="item in showCommentList" :key="item.time">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle">
            </div>
          </div>
        </div>

      </div>
      <button class="moreClass" @click="moreClick">{{ showText }}</button>
    </div>
    <div id="snippet-box">
      <div class="snippet-title">Summary</div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">Author Rating</div>
          <div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span></div>
          <div class="snippet-clear"></div>
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">Aggregate Rating</div>
            <div class="snippet-data-img"><span itemprop="ratingValue">3.65</span> based on <span
                class="rating-count" itemprop="ratingCount">46258</span> votes </div>
          </div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Name</div>
          <div class="snippet-data-img"> <span itemprop="name">GBWhatsApp APK</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Operating System</div>
          <div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Category</div>
          <div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Price</div>
          <div class="snippet-data-img"> <span itemprop="offers" itemscope=""
              itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Landing Page</div>
          <div class="snippet-data-img"> <a itemprop="featureList"
            href="https://gbapksdownload.com/ ">https://gbapksdownload.com/</a></div>
          <div class="snippet-clear"></div>
        </div>
      </div>
    </div>
    <Rate />
  </div>
</template>

<script>
// @ is an alias to /src
import "@/css/gb/pc.scss";
import "@/css/gb/mobile.scss";
import DownloadBtn from "@/components/DownloadBtn.vue";
import S2 from "./assets/section2.vue";
import S3 from "./assets/section3.vue";
import ApkDetail from "./assets/apkdetail.vue";
import Rate from "@/components/rate.vue";
import lazysource from "@/components/lazysource.vue";
import { allCommentList } from "@/utlis/allCommentList.js";
import Category from "../../components/category.vue";

export default {
  name: "gb",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    lazysource,
    Category,
    Rate,
  },
  data() {
    return {
      liarr: [
    ["Messaging Features", "GB WhatsApp has the same basic features like the official WhatsApp.", {name: 'dd1', alt: 'send message'}],
    ["Ghost Mode", "If GB WhatsApp was set under Ghost Mode, users can hide their activities as like they are offline.", {name: 'dd2', alt: 'gbwhatsapp ghost mode'}],
    ["Hide Double Ticks", "This is one of the popular options under Privacy Option. GB users can hide double ticks until they reply to messages. Users can read messages from others without letting them know."],
    ["Change Themes", "Themes can be changed within not only the default ones in the official WhatsApp. WhatsApp GB users can download the one they like among 4000+ choices. It's also available to change the wallpaper of GBWhatsApp."],
    ["Change Blue Ticks", "Can you image that Blue Ticks can be replaced with another icon? GB WhatsApp provides more than 10 icons for users to choose."],
    ["Share Images with Original Quality", "Go to Settings and enable the option to send images or videos of original quality. You can then share large images and long videos without being compressed. It's totally different from the official WhatsApp."],
    ["Anti-Revoked Messages", "Revoked messages will be stuck with a label. So, Android users of GBWhatsApp can see deleted or revoked messages easily. But, if you don't want to let others know that you're using GB WhatsApp, you'd better not reply to deleted messages. Moreover, deleted messages can also be found in chat history."],
    ["Video Call Limits", "It also provides a function to limit who can call you. The same like the normal WhatsApp.", {name: 'dd3', alt: 'calls limit'}],
    ["App Translation", "More languages were added for GB WhatsApp users across the world to translate messages in a chat."],
    ["Hide Profile Icon from Chats", "This feature allows users to conceal their profile picture from appearing in chat windows, providing an extra layer of privacy by preventing others from seeing their profile icon in conversations."],
    ["Go to the First Message in a Chat", "This feature enables users to quickly navigate to the very first message in a chat, making it easy to find the beginning message without manually scrolling through the entire chat history."]
    ],
      cate: [
        "1. How to fix 'You need the official WhatsApp to log in' issue?",
        "2. GBWhatsApp vs WhatsApp – What's the Difference?",
        "3. Summary of GBWhatsApp APK features",
        "4. GB WhatsApp Download and GB WhatsApp Update",
        "5. How to Backup GB WhatsApp Chats to Google Drive",
      ],
      comment: {
        name: "",
        email: "",
        content: "",
      },
      clickNum: 0,
      pageName: "home",
      doc: null,
      myApp: null,
      from: null,
      filename: null,
      isOffical: 0,
      feature: [
        {
          feature: "Hide Online Status",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Airplane Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Status Download",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Add Custom Fonts/Stickers",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Media Sharing",
          gb: "Maximum 200MB",
          whs: "Maximum 15MB",
        },
        {
          feature: "Status Character Length",
          gb: "Maximum 255",
          whs: "Maximum 139",
        },
        {
          feature: "Themes Supported",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "DND Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Freeze Last Seen",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable Forwarded Tag",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable/Customize Calling",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Anti-Delete Status/Messages",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Security Lock",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Fully Customize",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Auto Reply",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Increase Forward Limit",
          gb: "Maximum 250",
          whs: "Maximum 10",
        },
        {
          feature: "Increase Image Share Limit",
          gb: "Maximum 100",
          whs: "Maximum 30",
        },
      ],

      viewHeight: null,
      scrollEventFun: null,
      month: "",

      ratingStar: null,
      showCollect: false,
    };
  },
  created() {
    this.myApp = this.$store.state.gb;
    this.doc = this.myApp.doc;
  },
  beforeMount() {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    this.$logEvent(`show_gbhome_pc`);
  },
  computed: {
    showText() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return "Click Here To Load More Comments";
      } else {
        return "No More Comments";
      }
    },
    showCommentList() {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
    allCommentList() {
      const regularTime = Date.parse("2024/12/22 00:00:00");
      let currentTime = new Date().getTime();
      let Difference = Math.floor(
        (currentTime - regularTime) / (2 * 24 * 60 * 60 * 1000)
      );
      return (
        allCommentList
          .slice(0, 1 + Difference)
          .sort(
            (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
          ) || []
      );
    },
  },
  mounted() {
    console.log("mounted");

    let script = document.querySelector("#script");
    if (script) {
      return;
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.id = "script";
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3240446016388496"
    );
    recaptchaScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(recaptchaScript);

    setTimeout(() => {
      if (typeof window !== "undefined") {
        this.viewHeight = window.innerHeight;
        this.scrollEvent();
        // 监听scroll事件
        this.scrollEventFun = this.$global.throttle(this.scrollEvent, 300);
        window.addEventListener("scroll", this.scrollEventFun, false);
      }

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },
  beforeDestroy() {
    // 防止内存溢出
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.scrollEventFun, false);
    }
  },
  methods: {
    submitClick() {
      this.$Dialog
        .confirm({
          title: "Tip",
          message:
            "Your comment has been submitted successfully and will be displayed below after review.",
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
        })
        .then(() => {
          this.comment.name = "";
          this.comment.email = "";
          this.comment.content = "";
        })
        .catch(() => {
          console.log("取消");
        });
    },
    moreClick() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    getOffsetTop(element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop() {
      if (typeof window !== "undefined") {
        return (
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
        );
      }
    },
    scrollEvent() {
      let $scrollTop = this.scrollTop();
      let nodeArr = document.querySelectorAll(".has-exp");
      // console.log($scrollTop);

      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost(exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    download() {
      // this.$global.download(this.myApp.apk);
      this.$logEvent("click_gwhome_download");
      this.$router.push({
        name: "gb_download_pc",
        params: { apk: this.myApp.apk },
      });
    },
    gotochat() {
      if (typeof window !== "undefined") {
        // 在浏览器环境中执行的代码
        window.location.href = "https://www.whatsplus.online/";
      }
    },
  },
};
</script>
